<template>
    <div class="introduction">      
        <b-card>
            <b-alert show>
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
            
                <span>
                     Mit diesem Tool kannst du deinen Soll-Einsatzplan von <a href="http://www.adberlin.com/" target="_blank">Ambulante Dienste Berlin e.V.</a> automatisch in ein digitales <a href="https://de.wikipedia.org/wiki/ICalendar" target="_blank">Kalenderformat(.ics)</a> umwandeln und so in deinen Google Kalender, Apple iCal, Outlook o.Ä. importieren.
                </span>
            </b-alert>
            
                
            
           
        </b-card>
    </div>
</template>

<script>
export default {

}
</script>

<style>
    
</style>